<template>
  <main id="DownloadCenter">
    <div class="row">
      <header>
        <p class="sub_title"><b>DOWNLOAD CENTER</b></p>
        <h3 class="title title_mark"><b>檔案下載</b></h3>
      </header>
      <div class="content_box">
        <template v-for="card in files">
          <div class="card_list" :key="card.id">
            <h4>{{ card.title }}</h4>
            <ol>
              <li v-for="item in card.list" class="card" :key="item.id">
                <a
                  @click="GA_DownLoadFile(item.name)"
                  :href="item.file"
                  target="_blank"
                  class="link_mark"
                >
                  <div class="img_box">
                    <img :src="item.image" />
                  </div>
                  <p>{{ item.name }}</p>
                </a>
              </li>
            </ol>
          </div>
        </template>
      </div>
    </div>
    <MainFooter />
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import DownloadCenterGSAP from '@/common/GSAP/DownloadCenter/download_center.js';
import MainFooter from '@/components/MainFooter';
import { getFileList } from '@/api/download_center.js';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'DownloadCenter',
  mixins: [SmoothScrollResister, DownloadCenterGSAP],
  components: {
    MainFooter,
  },
  data() {
    return {
      files: [
        {
          title: '產品表單',
          list: [],
        },
      ],
      meta_data: null,
    };
  },
  methods: {
    async GetData() {
      getFileList().then((res) => {
        console.log(res.data);
        this.files[0].list = res.data;
        this.$nextTick(() => {
          this.RegisterGsap('DownloadCenter');
          this.$emit('init-cursor');
          this.InitCard(-1);
          window.prerenderReady = true;
        });
      });
    },
  },
  created() {
    this.GetData();
    this.meta_data = GetMetaData(
      '檔案下載',
      '茂建科技官方網站，您可以下載商品訂購單或其他商品使用說明書。',
      ''
    );
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
